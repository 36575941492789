import {
  AuthenticationService,
  CommentsService,
  PlaylistsStereoSpacesService,
} from 'sostereo-services';
import { PlayerAbstractController } from './player-abstract-controller';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';
import { firstValueFrom } from 'rxjs';
import { PlayerService } from 'src/app/angular/shared/services/player.service';
import { PlayerWidgetService } from 'src/app/angular/layout/services/player-widget.service';
import { PlayerWidgetTrackModel } from 'src/app/angular/shared/models/player-widget-track.model';
import { uniqBy } from 'lodash-es';

export class PlayerPlaylistController extends PlayerAbstractController {
  private playlistsService: PlaylistsStereoSpacesService;
  private trackingService: TrackingService;
  private commentsService: CommentsService;
  public plBasicInfo: any = {};
  public isOwnPlaylist = false;
  public comments: any = [];

  constructor(
    authenticationService: AuthenticationService,
    playlistsService: PlaylistsStereoSpacesService,
    trackingService: TrackingService,
    playerService: PlayerService,
    commentsService: CommentsService,
    playerWidgetService: PlayerWidgetService,
  ) {
    super(authenticationService, playerService, playerWidgetService);
    this.playlistsService = playlistsService;
    this.trackingService = trackingService;
    this.playerService = playerService;
    this.commentsService = commentsService;
  }

  sendTrackingPlaylistView(res: any, slug: string) {
    this.trackingService.track(
      'playlist view',
      {
        targetId: res.data._id,
        name: res.data.name,
        slug: slug,
        status: res.data.status,
        description: res.data.description,
        totalItems: res.data.tracks.paging.totalItems,
        createdAt: res.data.createdAt,
        updatedAt: res.data.updatedAt,
        ownerUid: res.data.ownerUid,
      },
      {
        event_action: 'Playlist view',
        event_type: 'Playlist View',
        event_value: res?.data?.tracks?.paging?.totalItems + 'items',
        element_type: 'Playlist',
        element_value: res?.data?.name,
        element_id: res?.data?._id,
      },
    );
  }

  getIsOwnPlaylist(playlist: any) {
    return !(
      !this.authenticationService.getCurrentUser() ||
      (this.authenticationService.getCurrentUser() &&
        playlist.ownerUid !== this.authenticationService.getCurrentUser().uid)
    );
  }

  getComments(playListId) {
    this.commentsService.query({ playListId: playListId }).subscribe(
      (res) => {
        this.comments = res.data.items;
        this.playerWidgetService.setComments(res.data.items);
      },
      (err) => {
        this.trackingService.track('Get comments error', {
          error: err,
          action: 'Review',
        });
      },
    );
  }

  async getData(params) {
    const { slug, queryParams } = params;
    try {
      const res = await firstValueFrom(this.playlistsService.get(slug, queryParams));
      this.sendTrackingPlaylistView(res, slug);
      this.plBasicInfo = {
        playlistId: res.data._id,
        playlistName: res.data.name,
        playlistOwnerUid: res.data.ownerUid,
      };
      this.getComments(res.data._id);
      this.playerService.updatePlaylistSEO(res);
      const playlistData = res.data;
      playlistData.items = playlistData.tracks.items;
      playlistData.paging = playlistData.tracks.paging;
      this.playerService.addSelectedVersion(playlistData);
      this.isOwnPlaylist = this.getIsOwnPlaylist(playlistData);
      return playlistData;
    } catch (err) {
      console.error('Error to get Playlist >>>> ', err);
      this.trackingService.track('Error getting playlist at player', {
        playerSlug: slug,
        error: err,
      });
    }
  }

  addNextPage(items: any[], newItems: any[]) {
    return [...items, ...newItems];
  }

  addPrevPage(items: any[], newItems: any[]) {
    return [...newItems, ...items];
  }

  public createQueueTrack(song: any) {
    const songObject = song?.versions?.match ? song?.versions?.match : song;
    let artistList = song?.versions?.match ? song?.versions?.match?.artists : song?.artists;
    return {
      url: songObject?.files
        ? songObject?.files.streaming
        : songObject?.fileUrls.streaming || songObject?.fileUrls.original || songObject?.fileUrls,
      name: songObject?.name || songObject?.fileName,
      trackId: songObject?.trackId || songObject?._id,
      objectData: { ...songObject, ...this.plBasicInfo },
      version: songObject?.songObjectVersion,
      fileType: 'mp3',
      rating: songObject.rating,
      segmentsGroups: songObject.segmentsGroups || songObject?.segmentsGroups,
      artists: artistList,
      paging: songObject.paging,
    } as any;
  }

  public setQueuePlayer(
    trackToWidget: PlayerWidgetTrackModel,
    items: any[],
    artists: any,
    noSongVersions?: boolean,
  ) {
    const queue = new Array<PlayerWidgetTrackModel>();
    items.forEach((song) => {
      if (
        (noSongVersions &&
          song?.versions?.match?.files &&
          song?.versions?.match?.files?.streaming !== '') ||
        (song?.versions?.match?.fileUrls && song?.versions?.match?.fileUrls?.streaming !== '') ||
        noSongVersions
      ) {
        let queueTrack = this.createQueueTrack(song);

        queueTrack.objectData.playlistId = this.plBasicInfo.playlistId;
        queueTrack.showNewComment = true;
        if (this.comments) {
          const songComments = this.comments[trackToWidget.trackId];
          if (songComments) {
            queueTrack.comments = songComments.times;
          }
        }

        if (
          this.authenticationService.getCurrentUser() &&
          queueTrack.objectData.soId &&
          queueTrack.objectData._id &&
          !queueTrack.objectData.hasOwnProperty('uri')
        ) {
          const tenantId = this.authenticationService.getCurrentUser().tenantId;
          queueTrack.objectData.uri = `${tenantId}:core:songs:${queueTrack.objectData.soId}/${queueTrack.objectData._id}`;
        }
        queue.push(queueTrack);
        queue[queue.length - 1].objectData.selectedArtist = queue[queue.length - 1].objectData
          .artists
          ? artists[queue[queue.length - 1].objectData.artists[0].artistId]
          : null;
      }
    });
    this.playerWidgetService.setQueue(queue);
    //this.playingQueue = uniqBy(queue, 'trackId');
    return uniqBy(queue, 'trackId');
  }

  public clearSearch() {}
}
