import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription, finalize } from 'rxjs';
import { AuthenticationService, PlaylistsStereoSpacesService } from 'sostereo-services';
import { Data, PlaylistData } from 'src/app/angular/modules/playlists/components/playlist.model';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';
import { PlayerService } from 'src/app/angular/shared/services/player.service';
import { transition, trigger, useAnimation } from '@angular/animations';
import { inOutAnimation } from 'src/app/angular/shared/animations/in-out.animation';
import { DownloadPlaylistConfirmationModalComponent } from 'src/app/angular/modules/modals/components/download-playlist-confirmation-modal/download-playlist-confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { PlayerWidgetService } from 'src/app/angular/layout/services/player-widget.service';
import { PlayerWidgetTrackModel } from 'src/app/angular/shared/models/player-widget-track.model';
import { AddToPlaylistModalComponent } from 'src/app/angular/modules/modals/components/add-to-playlist-modal/add-to-playlist-modal.component';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { clone, findIndex } from 'lodash-es';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonService } from 'src/app/angular/shared/services/common.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PlayerAbstractController } from 'src/app/angular/modules/player/classes/player-abstract-controller';
import { PlayerPlaylistController } from 'src/app/angular/modules/player/classes/player-playlist-controller';
import { SuggestionSongsService } from 'src/app/angular/shared/services/suggestion-songs.service';
import * as Sentry from '@sentry/browser';
import { GenericModalService } from 'src/app/angular/shared/services/generic-modal.service';
import { LicenseRequestModalComponent } from 'src/app/angular/modules/modals/components/license-request-modal/license-request-modal.component';

@Component({
  selector: 'app-playlist-menu-detail',
  templateUrl: './playlist-menu-detail.component.html',
  styleUrls: ['./playlist-menu-detail.component.scss'],
  animations: [trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))])],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistMenuDetailComponent implements OnInit, OnDestroy {
  @ViewChild('downloadPlaylistConfirmationModalComponent', { static: true })
  downloadPlaylistConfirmationModalComponent: DownloadPlaylistConfirmationModalComponent;

  @ViewChild('addToPlaylistModalComponent') addToPlaylistModal: AddToPlaylistModalComponent;

  @ViewChild('licenseRequestModalComponent', { static: true })
  licenseRequestModal: LicenseRequestModalComponent;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @ViewChild('target') private myScrollContainer: ElementRef;
  @ViewChild('mainSongs') private mainSongsContainer: ElementRef;

  @Input() playlistReference: any;
  @Input() editorial: any;
  @Output() duplicatePlaylistEvent = new EventEmitter();
  @Output() editPlaylist = new EventEmitter();
  @Output() removeTrackEvent = new EventEmitter<any>();
  @Output() rowMouseUp = new EventEmitter();
  @Output() playlistDetailsPlay = new EventEmitter();
  @Output() addSuggestionSong = new EventEmitter();
  private isFinished$: Subscription = new Subscription();
  private currentPage = 1;
  public loader = true;
  public playlist: Data;
  public artistsPlaylist: any[];
  public loadingDownload = false;
  private updatePlaylist$: Subscription;
  public tags = [];
  public loadingScroll = false;
  public songs = [];
  public currentSong: any = {};
  public currentIndexSong;
  public isAdmin = this.isAllowed(['*', 'ListTiers']);
  public onDropBorder = false;
  public draggedSong;
  public canEdit = false;
  public showMainPlaceholder = false;
  public playingQueue = [];
  public currentUser = this.authenticationService.getCurrentUser();
  private playerController: PlayerAbstractController;
  public recommendedSongs: any[] = [];
  public profiles = clone(this.suggestionSongsService.profiles);

  constructor(
    private playlistsService: PlaylistsStereoSpacesService,
    private cd: ChangeDetectorRef,
    private trackingService: TrackingService,
    private playerService: PlayerService,
    private playerWidgetService: PlayerWidgetService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private clipBoardService: ClipboardService,
    private commonService: CommonService,
    public deviceDetectorService: DeviceDetectorService,
    public suggestionSongsService: SuggestionSongsService,
    private genericModalService: GenericModalService,
  ) {
    this.playerController = new PlayerPlaylistController(
      authenticationService,
      playlistsService,
      trackingService,
      playerService,
      null,
      playerWidgetService,
    );
    this.subscribePlaylistChanges();
    this.subscribeFinishPlayerWidget();
  }

  ngOnDestroy(): void {
    this.updatePlaylist$?.unsubscribe();
    this.isFinished$?.unsubscribe();
  }

  ngOnInit(): void {
    this.getPlaylist();
  }

  subscribePlaylistChanges() {
    this.updatePlaylist$ = this.playlistsService.updatePlaylistEvent.subscribe({
      next: (playlist) => {
        if (this.playlist?._id === playlist?._id) {
          this.getPlaylist();
        }
      },
    });
  }

  public playPL() {
    const event = { playlistId: this.playlistReference._id, slug: this.playlistReference.slug };
    this.playlistDetailsPlay.emit(event);
  }

  playSong(songIndex) {
    this.currentSong = this.playlist.tracks.items[songIndex];
    this.currentIndexSong = songIndex;
    this.currentSong.isPlaying = true;
    this.cd.detectChanges();
    const trackToWidget = this.createTrackToWidgetPlayer();
    if (trackToWidget.url !== '') {
      this.playerWidgetService.autoPlay.next(true);
      this.playerWidgetService.playSong(trackToWidget);
      this.updateActiveSong(songIndex);
    }
    this.setQueuePlayer(trackToWidget);
    this.cd.detectChanges();
  }

  playRecommendedSong(songIndex) {
    this.recommendedSongs.forEach((song) => {
      song.isPlaying = false;
    });
    this.currentSong = this.recommendedSongs[songIndex];
    this.currentSong.isPlaying = true;
    this.cd.detectChanges();
    const trackToWidget = this.createTrackToWidgetPlayer(false);
    if (trackToWidget.url !== '') {
      this.playerWidgetService.autoPlay.next(true);
      this.playerWidgetService.playSong(trackToWidget);
      this.inactiveLastSong();
    }
    this.cd.detectChanges();
  }

  private setQueuePlayer(trackToWidget: PlayerWidgetTrackModel) {
    this.playingQueue = this.playerController.setQueuePlayer(
      trackToWidget,
      this.playlist.tracks.items,
      this.artistsPlaylist,
      true,
    );
  }

  private createTrackToWidgetPlayer(isPlaylistSong: boolean = true): PlayerWidgetTrackModel {
    let playlistInfo = {
      playlistId: this.playlist._id,
      playlistName: this.playlist.name,
      playlistOwnerUid: this.playlist.ownerUid,
    };
    let trackToWidget = new PlayerWidgetTrackModel();
    trackToWidget.url = this.getUrlCurrentSong();
    trackToWidget.name = this.currentSong.fileName || this.currentSong.name;
    trackToWidget.waveformUrl = this.currentSong.waveformUrl;
    trackToWidget.trackId = this.currentSong.trackId || this.currentSong._id;
    const objectData = isPlaylistSong
      ? { ...this.currentSong, ...playlistInfo }
      : { ...this.currentSong };
    trackToWidget.objectData = objectData;
    trackToWidget.version = this.currentSong.songVersion;
    trackToWidget.fileType = 'mp3';
    trackToWidget.artists = this.currentSong.artists;
    if (isPlaylistSong) {
      trackToWidget.paging = this.playlist.tracks.paging;
    }

    // This is to create a uri if the song doesn't have it
    if (
      this.authenticationService.getCurrentUser() &&
      trackToWidget.objectData.soId &&
      trackToWidget.objectData._id &&
      !trackToWidget.objectData.hasOwnProperty('uri')
    ) {
      const tenantId = this.authenticationService.getCurrentUser().tenantId;
      trackToWidget.objectData.uri = `${tenantId}:core:songs:${trackToWidget.objectData.soId}/${trackToWidget.objectData._id}`;
    }

    return trackToWidget;
  }

  inactiveLastSong() {
    const lastSongIndex = findIndex(this.playlist.tracks.items, (songElem: any) => {
      return (
        songElem.hasOwnProperty('isPlaying') &&
        songElem.isPlaying &&
        ((songElem.trackId && songElem.trackId !== this.currentSong.trackId) ||
          (songElem.id && songElem.id !== this.currentSong.id))
      );
    });
    if (lastSongIndex !== -1) {
      this.playlist.tracks.items[lastSongIndex].isPlaying = false;
    }
  }

  updateActiveSong(songIndex) {
    this.inactiveLastSong();
    this.playlist.tracks.items[songIndex].isPlaying = true;
    this.cd.detectChanges();
  }

  private getUrlCurrentSong() {
    return this.currentSong.files
      ? this.currentSong.files.streaming
      : this.currentSong.fileUrls.streaming ||
          this.currentSong.fileUrls.original ||
          this.currentSong.fileUrls;
  }

  getNameArtistBySong() {
    this.playlist.tracks.items.forEach((track) => {
      this.playerService.mapArtists(track.artists, this.playlist);
    });
  }

  public getPlaylist(page: number = 1) {
    this.currentPage = page;
    const playlistParam = this.editorial ? this.playlistReference.slug : this.playlistReference._id;
    this.playlistsService
      .get(playlistParam, { limit: 30, page })
      .pipe(
        finalize(() => {
          this.loader = false;
          this.cd.detectChanges();
          //this.createListener();
        }),
      )
      .subscribe({
        next: (res: PlaylistData) => {
          console.log('playlist res>>', res.data);
          if (res.data?.tracks?.items?.length > this.playlist?.tracks?.items?.length) {
            setTimeout(() => {
              this.scrollToElement();
            });
          }
          if (page === 1) {
            this.playlist = res.data;
            if (res.data?.tags) {
              this.tags = this.formatTags(res.data.tags);
            }
            this.canEdit = this.commonService.canEditPlaylist(this.playlist);
            if (this.playlist.tracks.paging.totalPages === 1) {
              this.getRecommendedSongs();
            }
          } else {
            const newPlaylist = res.data;
            this.playlist.tracks.items = this.playlist.tracks.items.concat(
              newPlaylist.tracks.items,
            );
            this.playlist.tracks.paging = newPlaylist.tracks.paging;
            this.cd.detectChanges();
          }
          this.artistsPlaylist = Object.values({ ...res.data.artists });
          this.cd.detectChanges();
          this.getNameArtistBySong();
          this.songs = this.playlist.tracks.items;
          this.currentSong = this.songs[0];
        },
        error: (err) => {
          console.error('Error to get playlistsStereoSpacesService >>>> ', err);
          this.trackingService.track('Error trying to get playlist', {
            playlistId: this.playlistReference._id,
            Error: err,
          });
          Sentry.captureException(
            new Error(
              `There was an error trying to get the playlist. API error: status ${err.status} - ${
                err?.error?.message || err?.message
              }`,
            ),
          );
        },
      });
  }

  formatTags(tagsArray: string[]) {
    return tagsArray.map((tag) => {
      if (tag.includes('rhythm-') || tag.includes('harmony-') || tag.includes('texture-')) {
        const words = tag.split('-');
        return words[0] + '-' + words[2];
      } else {
        return tag.split('-').slice(1).join('-');
      }
    });
  }

  duplicatePlaylist() {
    const event = { tableRow: { _id: this.playlist._id, name: this.playlist.name } };
    this.duplicatePlaylistEvent.emit(event);
  }

  downloadPlaylist() {
    if (this.playlist.tracks?.items?.length > 0) {
      const plBasicInfo = {
        playlistId: this.playlist._id,
        playlistName: this.playlist.name,
        playlistOwnerUid: this.playlist.ownerUid,
      };
      this.loadingDownload = true;
      this.downloadPlaylistConfirmationModalComponent.showModal(plBasicInfo);
    }
  }

  onProcessFinished() {
    this.loadingDownload = false;
  }

  public sendEditPlaylist() {
    if (this.canEdit) {
      this.cd.detectChanges();
      this.editPlaylist.emit({
        ...this.playlist,
        publicAccess: this.playlist.groups?.length > 0,
      });
      this.cd.detectChanges();
    }
  }

  public removeTrack(song) {
    this.onTrackRemoved(song);
  }

  private onTrackRemoved(event) {
    this.genericModalService.showModal(
      {
        title: 'Remove track',
        message: 'Are you sure you want to remove the track ' + event.songMatch?.fileName + '?',
        cssClass: 'fa fa-exclamation-triangle',
        size: 'modal-sm',
        btns: [
          {
            status: true,
            data: event,
            cssClass: 'primary-btn',
            title: 'Accept',
          },
          { kind: 'close', title: 'Cancel', cssClass: 'cancel-btn' },
        ],
      },
      this.onRemoveTrackModalClosed.bind(this),
    );
    this.trackingService.track(
      'Remove track from playlist modal open',
      {
        action: 'Modal Open',
        kind: 'Remove track from playlist modal',
        song: event.songMatch,
      },
      {
        event_action: 'Remove track from playlist modal open',
        event_type: 'Modal Opened',
        event_value: event.songMatch?.trackId,
      },
    );
  }

  public onRemoveTrackModalClosed(event) {
    if (event?.data) {
      const { songIndex, songMatch } = event.data;

      const song = this.playlist.tracks.items[songIndex];
      if (song.soId === songMatch.soId) {
        this.playlistsService
          .deleteTrack(this.playlist._id, song.trackId, { uri: song.uri })
          .subscribe({
            next: (res: any) => {
              if (res.data?.nModified === 1 || res.data?.modifiedCount === 1) {
                this.playlist.tracks.items.splice(songIndex, 1);
                this.playlist.tracks.paging.totalItems--;
                this.playlistsService.updatePlaylistEvent.next({
                  _id: this.playlist._id,
                  totalSongs: this.playlist.tracks.paging.totalItems,
                });
                this.cd.detectChanges();
                this.trackingService.track(
                  'Track removed from playlist detail',
                  {
                    action: 'Song Removed',
                    kind: 'Track removed from playlist detail',
                    song: song,
                  },
                  {
                    event_action: 'Track removed from playlist detail',
                    event_type: 'Song Removed',
                    event_value: song?.trackId,
                  },
                );
              } else {
                this.toastr.warning('There was an error removing song');
              }
            },
            error: (err) => {
              this.toastr.error(err?.error?.message, 'There was an error removing track');
              Sentry.captureException(
                new Error(
                  `There was an error removing song. API error: status ${err.status} - ${
                    err?.error?.message || err?.message
                  }`,
                ),
              );
            },
          });
      }
    }
  }

  addSongToPlaylist(song) {
    if (!song.hasOwnProperty('uri') && song.hasOwnProperty('soId') && song.hasOwnProperty('_id')) {
      const tenantId = this.authenticationService.getCurrentUser().tenantId;
      song.uri = `${tenantId}:core:songs:${song.soId}/${song._id}`;
    }
    this.addToPlaylistModal.tracksInput = [song];
    setTimeout(() => {
      this.addToPlaylistModal.showModal();
    });
  }

  isAuthenticated() {
    return (
      this.authenticationService.getCurrentUser() && !this.authenticationService.isTokenExpired()
    );
  }

  goToArtist(artistSlug) {
    this.trackingService.track(
      'Go to artist from playlist detail',
      {
        action: 'Go to artist from playlist detail',
        kind: 'Navigation',
        artistSlug: artistSlug,
      },
      {
        event_action: 'Go to artist from playlist detail',
        event_type: 'Navigation',
        event_value: artistSlug,
      },
    );
    if (artistSlug) {
      this.router.navigate(['/music-sync-licensing/artist', artistSlug]);
    }
  }

  getShareLink(song) {
    let relativeUrl = '';
    if (!song.soId) {
      this.toastr.error('There was an error getting share link for this song');
      this.trackingService.track('Error copying Song link to clipboard', {
        songName: song,
        songId: song,
        element: 'Song option',
      });
      Sentry.captureException(new Error('There was an copying Song link to clipboard'));
      return;
    }
    relativeUrl = `/music-sync-licensing/search?songId=${song.soId}&versionId=${
      song._id || song.trackId
    }`;
    this.clipBoardService.copy(`${window.location.hostname}${relativeUrl}`);
    this.toastr.info('Share link copied to clipboard');
    this.trackingService.track('Song link copied to clipboard', {
      songName: song,
      songId: song,
      element: 'Song option',
    });
  }

  onScroll() {
    if (this.playlist.tracks.paging.page < this.playlist.tracks.paging.totalPages) {
      this.getPlaylist(this.playlist.tracks.paging.page + 1);
    } else {
      if (this.recommendedSongs?.length === 0) {
        this.getRecommendedSongs();
      }
    }
  }

  onMouseUp() {
    if (this.onDropBorder) {
      this.onDropBorder = false;
      this.cd.detectChanges();
    }
    const tableRow = { ...this.playlist, leftToRight: true };
    this.rowMouseUp.emit({ tableRow });
  }

  public drop(event: CdkDragDrop<string[]>) {
    if (!this.canEdit && this.onDropBorder) {
      this.toastr.warning("You don't have permission to edit this playlist", 'Permission Denied');
      return;
    }
    // This is to prevent reordering a song if it moves outside the list
    if (!this.onDropBorder) {
      return;
    }
    this.onDropBorder = false;
    this.cd.detectChanges();
    moveItemInArray(this.playlist.tracks.items, event.previousIndex, event.currentIndex);
    const track = event.item.data;
    this.playlistsService
      .update(this.playlist._id, {
        track: { trackId: track.trackId, position: event.currentIndex },
      })
      .subscribe({
        next: () => {},
        error: (httpErr) => {
          const currentUser = this.authenticationService.getCurrentUser();
          if (currentUser || (!currentUser && httpErr?.error?.status !== 401)) {
            this.toastr.error(
              httpErr?.error?.message,
              'There was an error reordering the playlist',
            );
          }
          moveItemInArray(this.playlist.tracks.items, event.currentIndex, event.previousIndex);
          console.log(httpErr);
          this.trackingService.track('Error reordering a playlist song', {
            Error: httpErr,
          });
          Sentry.captureException(
            new Error(
              `There was an error reordering the playlist. API error: status ${httpErr.status} - ${
                httpErr?.error?.message || httpErr.message
              }`,
            ),
          );
        },
      });
  }

  onDragStarted(data) {
    this.draggedSong = data;
    this.playerService.draggedSong = data;
    this.playerService.draggedSong.lelftToRight = true;
    this.cd.detectChanges();
    this.trackingService.track(
      'Song drag left to right',
      {
        songData: data,
      },
      {
        event_action: 'Song drag left to right',
        event_type: 'Song Drag',
        event_value: data?.trackId,
      },
    );
  }

  onDragDropped() {
    this.trackingService.track(
      'Song drop left to right',
      {
        songData: this.draggedSong,
      },
      {
        event_action: 'Song drop left to right',
        event_type: 'Song Drop',
        event_value: this.draggedSong?.trackId,
      },
    );
    setTimeout(() => {
      this.draggedSong = undefined;
      this.playerService.draggedSong = undefined;
      this.cd.detectChanges();
    });
  }

  onMouseOver() {
    if (this.playerService.draggedSong) {
      this.onDropBorder = true;
      this.cd.detectChanges();
    }
  }

  onMouseLeave() {
    if (this.onDropBorder) {
      this.onDropBorder = false;
      this.cd.detectChanges();
    }
  }

  isAllowed(allowed, disallowed?) {
    return this.commonService.isAllowed(allowed, disallowed);
  }

  scrollToElement(): void {
    const top = this.mainSongsContainer.nativeElement.scrollHeight - 90;
    this.myScrollContainer.nativeElement.scroll({
      top: top > 0 ? top : 0,
      left: 0,
      behavior: 'smooth',
    });
    this.cd.detectChanges();
  }

  signupRequired() {
    const url = window.location.pathname + window.location.search;
    this.router.navigate(['/signup'], { queryParams: { returnUrl: url } });
  }

  handleMissingImage(event: Event, mainPlaceholder: boolean) {
    (event.target as HTMLImageElement).style.display = 'none';
    if (mainPlaceholder) {
      this.showMainPlaceholder = true;
    }
    this.cd.detectChanges();
  }

  subscribeFinishPlayerWidget() {
    this.isFinished$ = this.playerWidgetService.isFinished.subscribe(() => {
      const songPlaying = this.playerWidgetService.getCurrentTrack();
      const songId = songPlaying.objectData.trackId || songPlaying.objectData._id;
      const songIndex = findIndex(this.playlist.tracks.items, (songElem: any) => {
        return songElem.trackId === songId || songElem._id === songId;
      });
      if (songIndex !== -1) {
        this.currentSong = this.playlist.tracks.items[songIndex];
        this.currentIndexSong = songIndex;
        this.updateActiveSong(songIndex);
      }
      this.recommendedSongs.forEach((song) => (song.isPlaying = false));
      this.cd.detectChanges();
    });
  }

  public signUp() {
    const url = window.location.pathname + window.location.search;
    this.router.navigate(['/signup'], { queryParams: { returnUrl: url } });
  }

  public getRecommendedSongs() {
    const profile = this.profiles.find((p) => p.active);
    if (!profile) {
      this.profiles[0].active = true;
    }
    const params = profile && profile.value !== 'all' ? { profile: profile.value } : {};
    this.playlistsService
      .getRecommendedSongs(this.playlistReference._id, { ...params, limit: 10 })
      .subscribe({
        next: (res) => {
          this.recommendedSongs = res.data.items.map((item) => {
            const song = item._source.versions.find(
              (version) => version._id === item._source.preferredVersion,
            );
            return song || item._source.versions[0];
          });
          this.cd.detectChanges();
        },
        error: (err) => {
          console.error(err);
          this.trackingService.track('Error trying to get suggestion tracks by playlist', {
            playlistId: this.playlistReference._id,
            Error: err,
          });
        },
      });
  }

  public addRecommendedSong(song: any, songIndex: number) {
    const data = {
      event: { tableRow: { ...this.playlist } },
      song: { ...song },
      indexSuggestion: songIndex,
      profile: this.profiles.find((p) => p.active)?.value,
    };
    this.addSuggestionSong.emit(data);
  }

  changeProfile(value) {
    this.profiles.forEach((profile) => {
      profile.active = profile.value === value;
    });
    this.getRecommendedSongs();
  }

  public trackEvent(eventName, action, gaParams) {
    this.trackingService.track(
      eventName,
      {
        action: action,
        params: gaParams,
      },
      {
        ...gaParams,
      },
    );
  }

  setTableHeight() {
    const style = {
      height: 'calc(100vh - 320px)',
    };
    if (!(this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet())) {
      if (this.playlist.description && this.playlist.description !== '') {
        style.height = !this.playerWidgetService?.showPlayer
          ? 'calc(100vh - 345px)'
          : 'calc(100vh - 400px)';
      } else {
        style.height = !this.playerWidgetService?.showPlayer
          ? 'calc(100vh - 289px)'
          : 'calc(100vh - 349px)';
      }
    } else {
      if (this.playlist.description && this.playlist.description !== '') {
        style.height = !this.playerWidgetService?.showPlayer
          ? 'calc(100vh - 400px)'
          : 'calc(100vh - 480px)';
      } else {
        style.height = !this.playerWidgetService?.showPlayer
          ? 'calc(100vh - 350px)'
          : 'calc(100vh - 405px)';
      }
    }
    return style;
  }

  licenseSong(song) {
    const track = clone(song);
    if (!track?.name) {
      track.name = track.fileName;
    }
    this.licenseRequestModal.trackInput = track;
    setTimeout(() => {
      this.licenseRequestModal.showModal();
    });
  }
}
