export function playlistMenuTable(paging, isMobileOrTablet: boolean) {
  return {
    paging: paging,
    sorting: {
      createdAt: 'asc', // initial sorting
    },
    columns: [
      {
        field: 'name',
        title: '',
        show: true,
        dataType: 'textConcat',
        textConcat: [
          {
            field: 'name',
            class: 'playlist-name',
          },
          {
            field: 'createdAt',
            dataType: 'date',
            class: 'playlist-date',
            join: true,
          },
          {
            field: 'ownerName',
            prefixDot: '&middot;',
            class: 'playlist-date',
            join: true,
          },
          {
            field: 'editorial',
            prefixDot: '&middot;',
            booleanValue: 'Editorial',
            class: 'playlist-date',
            join: true,
          },
        ],
        width: '75',
        padding: '5',
      },
      {
        field: 'totalSongs',
        title: '',
        show: true,
        dataType: 'text',
        width: '15',
        alignment: 'center',
      },
      {
        field: 'navigation',
        title: '',
        show: true,
        iconClass: 'fa fa-play-circle',
        dataType: 'icon',
        width: '15',
        displayIf: isMobileOrTablet ? false : undefined,
        alignment: 'center',
      },
      {
        field: 'menu',
        title: '',
        show: true,
        iconDropdown: 'fa fa-ellipsis-h',
        dropdownAlignment: 'left',
        showitemSelected: false,
        dataType: 'dropdown',
        width: '10',
        alignment: 'left',
        ddlItems: [
          { value: 'delete', label: 'Delete Playlist', icon: 'glyphicon glyphicon-trash mr5' },
          { value: 'duplicate', label: 'Duplicate Playlist', icon: 'fa fa-files-o mr5' },
        ],
      },
    ],
  };
}
