import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaylistMenuComponent } from './playlist-menu/playlist-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxPaginationModule } from 'ngx-pagination';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown-ivy';
import { MatMenuModule } from '@angular/material/menu';
import { PlaylistMenuDetailComponent } from './playlist-menu-detail/playlist-menu-detail.component';
import { PlaylistsEditorialComponent } from './playlists-editorial/playlists-editorial.component';
import { SharedModule } from 'src/app/angular/shared/shared.module';
import { ModalsModule } from 'src/app/angular/modules/modals/modals.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableComponent } from 'src/app/angular/shared/components/table/table.component';

@NgModule({
  declarations: [PlaylistMenuComponent, PlaylistMenuDetailComponent, PlaylistsEditorialComponent],
  imports: [
    CommonModule,
    SharedModule,
    ModalsModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    DragDropModule,
    NgxPaginationModule,
    MatTooltipModule,
    AngularMultiSelectModule,
    MatMenuModule,
    TableComponent,
  ],
  exports: [PlaylistMenuComponent, PlaylistMenuDetailComponent, PlaylistsEditorialComponent],
})
export class PlaylistsSharedModule {}
