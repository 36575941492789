<section
  class="col-xs-12 editorial-section"
  style="overflow: auto"
  infiniteScroll
  [infiniteScrollDistance]="3"
  [infiniteScrollDisabled]="loading"
  (scrolled)="onScrollEvent()"
  [scrollWindow]="false"
  *ngIf="!loading"
  [ngClass]="{ pb100: deviceDetectorService.isMobile() }"
  [ngStyle]="{
    'max-height': playerWidgetService?.showPlayer ? 'calc(100vh - 180px)' : 'calc(100vh - 120px)'
  }"
>
  <div
    *ngFor="let playlist of playlists"
    class="pt10 pl0 pr0 card-editorial col-xs-12"
    (click)="selectCard(playlist)"
  >
    <div style="position: relative">
      <div class="card" style="bottom: 0px; right: 0px; width: 100%; z-index: 0; overflow: hidden">
        <div class="card-gradient"></div>
        <img
          *ngIf="playlist.thumbUrl"
          [src]="playlist.thumbUrl"
          alt="playlist"
          class="mb10 background-card"
          (error)="handleMissingImage($event)"
        />
        <p class="clamp-lines-2 pl-name">{{ playlist.name }}</p>
        <span
          class="material-icons absolute-center placeholder-icon"
          [attr.data-icon]="'playlist_play'"
          style="font-size: 50px; color: #cecece; z-index: -1"
        >
        </span>
      </div>
      <div
        class="actions"
        style="
          position: absolute;
          bottom: 0px;
          right: 0px;
          width: 100%;
          padding-top: 100%;
          background-color: #00000063;
        "
      >
        <span
          *ngIf="!deviceDetectorService.isMobile()"
          class="material-icons-outlined absolute-center"
          style="font-size: 40px; color: #cecece; position: absolute"
          [attr.data-icon]="'play_circle_filled'"
          (click)="$event.stopPropagation(); navigateToCard(playlist)"
        >
        </span>
      </div>
    </div>

    <div class="d-flex col-xs-12 pl0 pr0 justify-content-space-between">
      <div class="w100-percent">
        <div class="aditional-info">
          <div>
            <div>
              {{ playlist?.genereTagsFormatted[0] | titlecase
              }}{{ '&nbsp;' + '&middot;' + '&nbsp;' }}
            </div>
            <div>
              {{ playlist?.genereTagsFormatted[1] | titlecase
              }}{{ '&nbsp;' + '&middot;' + '&nbsp;' }}
            </div>
            <div>{{ playlist?.moodTags[0] | titlecase }}{{ '&nbsp;' + '&middot;' + '&nbsp;' }}</div>
            <div>{{ playlist?.moodTags[1] | titlecase }}</div>
            <div>{{ '&nbsp;' + '&middot;' }}{{ playlist?.moodTags[2] | titlecase }}</div>
            <div>{{ '&nbsp;' + '&middot;' }}{{ playlist?.moodTags[3] | titlecase }}</div>
            <div>{{ '&nbsp;' + '&middot;' }}{{ playlist?.moodTags[4] | titlecase }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
