import { AuthenticationService } from 'sostereo-services';
import { PlayerWidgetService } from 'src/app/angular/layout/services/player-widget.service';
import { PlayerWidgetTrackModel } from 'src/app/angular/shared/models/player-widget-track.model';
import { PlayerService } from 'src/app/angular/shared/services/player.service';

export abstract class PlayerAbstractController {
  protected authenticationService: AuthenticationService;
  public playerService: PlayerService;
  public playerWidgetService: PlayerWidgetService;

  constructor(
    authenticationService: AuthenticationService,
    playerService: PlayerService,
    playerWidgetService: PlayerWidgetService,
  ) {
    this.authenticationService = authenticationService;
    this.playerService = playerService;
    this.playerWidgetService = playerWidgetService;
  }

  private gerUrlBySong(song: any) {
    return song.files
      ? song.files.streaming
      : song.fileUrls.streaming || song.fileUrls.original || song.fileUrls;
  }

  private setUriByTrack(track: PlayerWidgetTrackModel) {
    if (
      this.authenticationService.getCurrentUser() &&
      track.objectData.soId &&
      track.objectData._id &&
      !track.objectData.hasOwnProperty('uri')
    ) {
      const tenantId = this.authenticationService.getCurrentUser().tenantId;
      track.objectData.uri = `${tenantId}:core:songs:${track.objectData.soId}/${track.objectData._id}`;
    }
  }

  public createTrackToWidgetPlayer(
    version: any,
    completeSong: any,
    artists: any,
    playlistInfo?: any,
    comments?: any,
  ) {
    try {
      let trackToWidget = new PlayerWidgetTrackModel();
      trackToWidget.url = this.gerUrlBySong(version);
      trackToWidget.name = version.name;
      trackToWidget.waveformUrl = version.waveformUrl;
      trackToWidget.trackId = version.trackId || version._id;
      trackToWidget.objectData = { ...playlistInfo, ...version };
      trackToWidget.version = version.songVersion;
      trackToWidget.fileType = 'mp3';
      trackToWidget.artists = version.artists;
      trackToWidget.rating = completeSong.rating;
      trackToWidget.segmentsGroups =
        completeSong.segmentsGroups || trackToWidget.objectData.segmentsGroups;
      trackToWidget.objectData.selectedArtist = version.artists
        ? artists[version.artists[0].artistId]
        : null;
      trackToWidget.paging = version.paging;
      trackToWidget.hideOptions = version.hideOptions || false;
      trackToWidget.versions = version?.versions;
      if (playlistInfo?.playlistId) {
        trackToWidget = this.setCommentsInTrack(trackToWidget, playlistInfo.playlistId, comments);
      }
      // This is to create a uri if the song doesn't have it
      this.setUriByTrack(trackToWidget);
      return trackToWidget;
    } catch (e) {
      console.error(e);
      return {} as PlayerWidgetTrackModel;
    }
  }

  private setCommentsInTrack(trackToWidget: PlayerWidgetTrackModel, plId, comments) {
    // This is to set the comments in the PW
    trackToWidget.showNewComment = true;
    trackToWidget.objectData.playlistId = plId;
    if (comments) {
      const songComments = comments[trackToWidget.trackId];
      if (songComments) {
        trackToWidget.comments = songComments.times;
      }
    }
    return trackToWidget;
  }

  public getSimilarSong(queryParams) {
    return this.playerService.getSongReference(queryParams, []);
  }

  abstract getData(params: any);

  abstract addNextPage(items: any[], newItems: any[]);
  abstract addPrevPage(items: any[], newItems: any[]);
  public abstract createQueueTrack(song: any);
  public abstract clearSearch();
  public abstract setQueuePlayer(
    trackToWidget: PlayerWidgetTrackModel,
    items: any[],
    artists: any,
    noVersions?: boolean,
  );
}
