import { Injectable } from '@angular/core';
import { LabelType } from 'ngx-slider-v2';
import { BehaviorSubject, Subject } from 'rxjs';

interface IRange {
  value: Date[];
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class SuggestionSongsService {
  public showPlaylistSettings = new BehaviorSubject<boolean>(false);
  public settingList = this.searchBarSection();
  public changeSetting = new Subject<void>();

  public profiles = [
    {
      active: true,
      label: 'All',
      value: 'all',
    },
    {
      active: false,
      label: 'Recent',
      value: 'recent',
    },
    {
      active: false,
      label: 'Undiscovered',
      value: 'undiscovered',
      allowed: ['ListTiers'],
    },
    {
      active: false,
      label: 'Hidden Gems',
      value: 'hiddenGems',
    },
  ];

  ranges: IRange[] = [
    {
      value: [new Date(new Date().setFullYear(new Date().getFullYear() - 2)), new Date()],
      label: 'Last 2 years',
    },
    {
      value: [new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()],
      label: 'Last year',
    },
    {
      value: [new Date(new Date().setMonth(new Date().getMonth() - 6)), new Date()],
      label: 'Last 6 months',
    },
    {
      value: [new Date(new Date().setMonth(new Date().getMonth() - 3)), new Date()],
      label: 'Last 3 months',
    },
    {
      value: [new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()],
      label: 'Last month',
    },
  ];

  public setChanges() {
    console.log(this.settingList);
    this.changeSetting.next();
  }

  public searchBarSection() {
    return [
      {
        category: 'Similar Tags',
        items: [
          {
            slug: 'suggestionBoost',
            label: 'Boost',
            category: 'Similar Tags',
            showLabel: false,
            value: 0.5,
            defaultValue: 0.5,
            format: '',
            options: {
              id: 'suggestionBoost',
              floor: 0,
              ceil: 5,
              step: 0.1,
              precision: 1,
              animate: false,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
          {
            slug: 'suggestionMaxQueryTerms',
            label: 'Max Query Terms',
            category: 'Similar Tags',
            sysAdmin: true,
            showLabel: false,
            value: 25,
            defaultValue: 25,
            format: '',
            options: {
              id: 'suggestionMaxQueryTerms',
              floor: 1,
              ceil: 100,
              animate: false,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
          {
            slug: 'suggestionMinTermFreq',
            label: 'Min Term Freq',
            category: 'Similar Tags',
            sysAdmin: true,
            showLabel: false,
            value: 30,
            defaultValue: 30,
            format: '',
            options: {
              id: 'suggestionMinTermFreq',
              floor: 0,
              ceil: 100,
              animate: false,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
          {
            slug: 'suggestionMinDocFrequency',
            label: 'Min Doc Freq',
            category: 'Similar Tags',
            sysAdmin: true,
            showLabel: false,
            value: 50,
            defaultValue: 50,
            format: '',
            options: {
              id: 'suggestionMinDocFrequency',
              floor: 0,
              ceil: 100,
              animate: false,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
        ],
      },
      {
        category: 'Curators',
        items: [
          {
            slug: 'curatorsModifier',
            label: 'Curators Modifier',
            value: 'log1p',
            defaultValue: 'log1p',
            format: '',
            allowedScopes: ['ListTiers'],
            sysAdmin: false,
            options: [
              { value: 'log1p', label: 'More added to playlists ' },
              { value: 'reciprocal', label: 'Less added to playlists ' },
            ],
            type: 'select',
          },
          {
            slug: 'curatorsModifier',
            label: 'Curators Modifier',
            value: 'log1p',
            defaultValue: 'log1p',
            format: '',
            sysAdmin: true,
            options: [
              { value: 'none' },
              { value: 'log1p' },
              { value: 'log2p' },
              { value: 'ln1p' },
              { value: 'ln2p' },
              { value: 'square' },
              { value: 'sqrt' },
              { value: 'reciprocal' },
            ],
            type: 'select',
          },
          {
            slug: 'curatorsFactor',
            label: 'Curators Factor',
            value: 1,
            defaultValue: 1,
            format: '',
            sysAdmin: true,
            options: {
              id: 'curatorsFactor',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'curatorsWeight',
            label: 'Order results by the most listened to music first',
            value: 1.8,
            defaultValue: 1.8,
            format: '',
            options: {
              id: 'curatorsWeight',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
        ],
      },
      {
        category: 'Recent Content',
        items: [
          {
            slug: 'recentWeight',
            label: 'Order results by most recently added songs',
            category: 'Recent Content',
            value: 1,
            defaultValue: 1,
            format: '',
            options: {
              id: 'recentWeight',
              floor: 0,
              ceil: 5,
              animate: false,
              enforceStep: false,
              step: 0.1,
              precision: 1,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'All Time';
                }
                if (label === LabelType.Ceil) {
                  return 'Just Added';
                }
                return `${value}`;
              },
            },
          },
          {
            slug: 'recentScale',
            label: 'Recent Scale',
            category: 'Recent Content',
            value: 200,
            defaultValue: 200,
            format: 'd',
            sysAdmin: true,
            options: {
              id: 'recentScale',
              floor: 0,
              ceil: 500,
              animate: false,
            },
          },
          {
            slug: 'offset',
            label: 'Offset',
            category: 'Recent Content',
            value: 15,
            defaultValue: 15,
            format: 'd',
            sysAdmin: true,
            options: {
              id: 'offset',
              floor: 0,
              ceil: 500,
              animate: false,
            },
          },
          {
            slug: 'decay',
            label: 'Decay ',
            category: 'Recent Content',
            value: 0.5,
            defaultValue: 0.5,
            format: '',
            sysAdmin: true,
            options: {
              id: 'decay',
              floor: 0,
              ceil: 1,
              animate: false,
              enforceStep: false,
              step: 0.1,
              precision: 1,
            },
          },
        ],
      },
      {
        category: 'Random',
        items: [
          {
            slug: 'randomWeight',
            label: 'Select level of randomization in results',
            category: 'Random',
            value: 1.2,
            defaultValue: 1.2,
            format: '',
            options: {
              id: 'randomWeight',
              floor: 0,
              ceil: 5,
              enforceStep: false,
              step: 0.1,
              precision: 1,
              animate: false,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
          {
            slug: 'maxBoost',
            label: 'Max Boost',
            category: 'Random',
            value: 10,
            defaultValue: 10,
            format: '',
            sysAdmin: true,
            options: {
              id: 'maxBoost',
              floor: 0,
              enforceStep: false,
              ceil: 50,
              animate: false,
            },
          },
          {
            slug: 'minMatch',
            label: 'Minimum Tag Match Percentage',
            category: 'Random',
            value: 0,
            defaultValue: 0,
            userInput: false,
            format: '%',
            options: {
              id: 'minMatch',
              floor: 0,
              enforceStep: false,
              ceil: 100,
              animate: false,
              translate: (value: number, label: LabelType): string => {
                return (label === LabelType.Low && value === 0) || label === LabelType.Floor
                  ? 'Auto'
                  : `${value}`;
              },
            },
          },
        ],
      },
      {
        category: 'Quality of Performance',
        items: [
          {
            slug: 'performanceModifier',
            label: 'Performance Modifier',
            value: 'log1p',
            defaultValue: 'log1p',
            format: '',
            sysAdmin: true,
            options: [
              { value: 'none' },
              { value: 'log1p' },
              { value: 'log2p' },
              { value: 'ln1p' },
              { value: 'ln2p' },
              { value: 'square' },
              { value: 'sqrt' },
              { value: 'reciprocal' },
            ],
            type: 'select',
          },
          {
            slug: 'performanceFactor',
            label: 'Performance Factor',
            value: 1,
            defaultValue: 1,
            format: '',
            sysAdmin: true,
            options: {
              id: 'performanceFactor',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'performanceMissing',
            label: 'Performance Missing',
            value: 0,
            defaultValue: 0,
            format: '',
            sysAdmin: true,
            options: {
              id: 'performanceMissing',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'performanceWeight',
            label: 'Performance Weight',
            value: 0,
            defaultValue: 0,
            format: '',
            allowedScopes: ['ListTiers'],
            options: {
              id: 'performanceWeight',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
        ],
      },
      {
        category: 'Quality of Recording',
        items: [
          {
            slug: 'recordingModifier',
            label: 'Recording Modifier',
            value: 'log1p',
            defaultValue: 'log1p',
            format: '',
            sysAdmin: true,
            options: [
              { value: 'none' },
              { value: 'log1p' },
              { value: 'log2p' },
              { value: 'ln1p' },
              { value: 'ln2p' },
              { value: 'square' },
              { value: 'sqrt' },
              { value: 'reciprocal' },
            ],
            type: 'select',
          },
          {
            slug: 'recordingFactor',
            label: 'Recording Factor',
            value: 1,
            defaultValue: 1,
            format: '',
            sysAdmin: true,
            options: {
              id: 'recordingFactor',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'recordingMissing',
            label: 'Recording Missing',
            value: 0,
            defaultValue: 0,
            format: '',
            sysAdmin: true,
            options: {
              id: 'recordingMissing',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'recordingWeight',
            label: 'Recording Weight',
            value: 0.5,
            defaultValue: 0.5,
            format: '',
            allowedScopes: ['ListTiers'],
            options: {
              id: 'recordingWeight',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
        ],
      },
      {
        category: 'Quality of Songwriting/Composition',
        items: [
          {
            slug: 'qualityModifier',
            label: 'Quality Modifier',
            value: 'log1p',
            defaultValue: 'log1p',
            format: '',
            sysAdmin: true,
            options: [
              { value: 'none' },
              { value: 'log1p' },
              { value: 'log2p' },
              { value: 'ln1p' },
              { value: 'ln2p' },
              { value: 'square' },
              { value: 'sqrt' },
              { value: 'reciprocal' },
            ],
            type: 'select',
          },
          {
            slug: 'qualityFactor',
            label: 'Quality Factor',
            value: 1,
            defaultValue: 1,
            format: '',
            sysAdmin: true,
            options: {
              id: 'qualityFactor',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'qualityMissing',
            label: 'Quality Missing',
            value: 0,
            defaultValue: 0,
            format: '',
            sysAdmin: true,
            options: {
              id: 'qualityMissing',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'qualityWeight',
            label: 'Quality Weight',
            value: 0.5,
            defaultValue: 0.5,
            format: '',
            allowedScopes: ['ListTiers'],
            options: {
              id: 'qualityWeight',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
        ],
      },
      {
        category: 'Popularity',
        items: [
          {
            slug: 'popularityModifier',
            label: 'Popularity Modifier',
            value: 'log1p',
            defaultValue: 'log1p',
            format: '',
            sysAdmin: true,
            options: [
              { value: 'none' },
              { value: 'log1p' },
              { value: 'log2p' },
              { value: 'ln1p' },
              { value: 'ln2p' },
              { value: 'square' },
              { value: 'sqrt' },
              { value: 'reciprocal' },
            ],
            type: 'select',
          },
          {
            slug: 'popularityFactor',
            label: 'Popularity Factor',
            value: 1,
            defaultValue: 1,
            format: '',
            sysAdmin: true,
            options: {
              id: 'popularityFactor',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'popularityMissing',
            label: 'Popularity Missing',
            value: 0,
            defaultValue: 0,
            format: '',
            sysAdmin: true,
            options: {
              id: 'performanceMissing',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'popularityWeight',
            label: 'Popularity Weight',
            value: 0.5,
            defaultValue: 0.5,
            format: '',
            options: {
              id: 'popularityWeight',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
        ],
      },
    ];
  }
}
